import { MAIN_SCHEMA } from 'constants/schema';
import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import HomePageComponent from 'style-book/home-page/HomePageComponent';
import SeoComponent from 'style-book/seo/SeoComponent';
import TextComponent from 'style-book/text/TextComponent';

import './PrivacyPolicyScreen.scss';

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
export default function PrivacyPolicyScreen() {
  return (
    <HomePageComponent>
      <div className="privacy-policy-screen">
        <div className="privacy-policy-content">
          <TextComponent variant={TEXT_VARIANTS.TITLE}>Privacy Policy</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.HEADING}>Last Updated: June 16, 2024</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.HEADING}>Welcome to goalskeeper.io!</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`goalskeeper.io values your privacy and the protection of your personal data. This privacy policy describes what information we collect from you, how we collect it, how we use it, how we obtain your consent, how long we retain it in our databases, and, if necessary, with whom we share it.`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`By registering as a user and using the platform, you are accepting the practices described in this privacy policy. Your use of the platform is also subject to our terms and conditions.`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`This privacy policy may change from time to time. Your continued use of the platform after we make changes is deemed acceptance of those changes, so please check the policy periodically for updates. This privacy policy has been prepared and is maintained in accordance with all applicable national and international laws and regulations and, in particular, with Israel's personal data protection laws (5741-1981) and the General Data Protection Regulation ("GDPR" - European regulation).`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            1. GENERAL INFORMATION
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`The personal data of the users that are collected and processed through the platform:`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.LINK}>https://goalskeeper.io</TextComponent>
            </TextComponent>
            {`Will be under responsibility and in charge of: `}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.LINK} href="mailto:legal@goalskeeper.io">
                legal@goalskeeper.io
              </TextComponent>
            </TextComponent>
            {`(Hereinafter referred to as “goalskeeper.io”).`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            2. TYPES OF INFORMATION GATHERED
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`The information we collect from our users helps us to personalize and continually improve the user experience on the platform. These are the types of information we collect:`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
              Information You Give Us.
            </TextComponent>
            {` You provide information when you provide, search, read and view content through the platform, register as a user, use our platform, and/or communicate with us through our contact information or our contact forms. As a result of those actions, you might supply us with the following information:`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`First and last name`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Email address`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`User content`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Any additional information relating to you that you provide to us directly or indirectly through our platform.`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`goalskeeper.io will not collect any personally identifiable information about you unless you provide it.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
              Information Collected Automatically:
            </TextComponent>
            {` By accessing and using the platform you automatically provide us with the following information:`}
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`The device and usage information you use to access the platform`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Your IP address`}</TextComponent>
            <TextComponent
              variant={TEXT_VARIANTS.BULLET}>{`Browser and device characteristics`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Operating system`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Referring URLs`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Your location`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`What parts of the platform you use and how often`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`If you access the platform through a mobile phone, we will collect the following information:`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Mobile device ID`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Model and manufacturer`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Operating system`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Version information`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`IP address`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
              Payment information:
            </TextComponent>
            {`Your credit/debit card details or payment information will be processed by the payment processors available on the platform `}
            <TextComponent
              variant={TEXT_VARIANTS.LINK}
              href="https://paddle.com/">{`Paddle`}</TextComponent>
            {`, which will process and store your data securely and for the sole purpose of processing transactions within the platform and the purchase of the services. goalskeeper.io reserves the right to engage any payment platform available on the market, which will process your data for the sole purpose of processing transactions within the platform and the purchase of the services.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>GOOGLE Analytics.</TextComponent>
            {` We use Google Analytics provided by Google, Inc., USA (“Google”). These tools and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the platform and improve our service. `}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`Please consult Google's privacy policy here:`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent
                variant={TEXT_VARIANTS.LINK}
                href="https://policies.google.com/privacy">{`https://policies.google.com/privacy`}</TextComponent>
            </TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>Facebook Pixel:</TextComponent>
            {` Our platform uses the Facebook Pixel. Through the Facebook Pixel, we can collect user information for different purposes. We use the Facebook Pixel for the following purposes:`}
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Collect statistics about our platform (for example, the number of users who visited a page).`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Collect information about how you interact with our platform (for example, whether you opened or followed links contained in them).`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Personalize online services and marketing communications.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Tailor advertisements to users and optimize advertising campaigns.`}</TextComponent>
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`The information collected through the Facebook Pixel will be collected and stored by Facebook and will be treated in accordance with its privacy policy. The information we collect through the Facebook Pixel does not personally identify the user and will never be used for purposes other than those contained in this privacy policy and Facebook's privacy policy.`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`Please consult Facebook's privacy policy here:`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent
                variant={TEXT_VARIANTS.LINK}
                href="https://www.facebook.com/privacy/explanation">{`https://www.facebook.com/privacy/explanation`}</TextComponent>
            </TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>Social Media:</TextComponent>
            {`On our platform, you will find links and functions linked to different social media, in which you can share your information.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`It is advisable to consult the privacy policy and data protection of each social media used on our platform.`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              {`Twitter:  `}
              <TextComponent
                variant={TEXT_VARIANTS.LINK}
                href="https://twitter.com/en/privacy">{`https://twitter.com/en/privacy`}</TextComponent>
            </TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>Google APIs.</TextComponent>
            {` goalskeeper.io use and transfer of information received from Google APIs to any other app will adhere to `}
            <TextComponent
              variant={TEXT_VARIANTS.LINK}
              href="https://developers.google.com/terms/api-services-user-data-policy">
              Google API Services User Data Policy
            </TextComponent>
            {`, including the Limited Use requirements.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            3. HOW LONG WE KEEP YOUR DATA
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`Personal data provided by users through the platform will be retained for the time necessary to provide the functionalities within the platform and until the user retains their account on the website or decides to close it or until goalskeeper.io closes and deletes the user's account. goalskeeper.io may retain personal data for a longer period where the user has given consent to such processing, provided that such consent is not withdrawn. In addition, goalskeeper.io may be obliged to retain personal data for a longer period if this is necessary for compliance with a legal obligation or by order of an authority. Once the retention period expires, the personal data will be deleted. Therefore, the right of access, the right of erasure, the right of rectification, and the right to data portability cannot be asserted once the retention period has expired.`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            4. HOW WE USE YOUR INFORMATION.{' '}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`In general, we use the information we collect primarily to provide, maintain, protect and improve our platform and services. We use personal information collected through our platform as described below:`}
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Identify you as a user in our system.`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`User registration.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Provide the functionalities available on the platform.`}</TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>{`Process payments.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Improve our platform and how we operate our business.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Understand and enhance your experience using our platform.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Respond to your comments or questions through our support team.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Send you related information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Communicate with you about upcoming events, offers, and news about services offered by goalskeeper.io and our selected partners.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Marketing purposes of goalskeeper.io.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Link or combine your information with other information we get from third parties to help understand your needs and provide you with better service.`}</TextComponent>
            <TextComponent
              variant={
                TEXT_VARIANTS.BULLET
              }>{`Protect, investigate and deter against fraudulent, unauthorized, or illegal activity.`}</TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            5. HOW DO YOU GET MY CONSENT?
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`By registering as a user, using the functionalities available within the platform, communicating with us through the contact forms or our contact information, and providing us with personal information to communicate with you, you consent to our collection, storage, and use of your information on the terms contained in this privacy policy. You can withdraw your consent by sending us your request via the contact information or the contact page.`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            6. HOW WE SHARE INFORMATION
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`The personal information of our users is an important and fundamental part of our business. Under no circumstances will we sell or share information with third parties that have not been previously authorized by the user or owner of the personal data. We share user information solely and exclusively as described below.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
              Third-Party Service Providers.
            </TextComponent>
            {` We use third-party services to perform certain functions on our behalf and through our platform and website. The third-party services we use on our platform and website are: creating and hosting the website, processing payments (`}
            <TextComponent
              variant={TEXT_VARIANTS.LINK}
              href="https://paddle.com/">{`Paddle`}</TextComponent>
            {`), sending emails, analyzing data (Google Analytics), and creating ads (Facebook, Google).`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`These third-party services and tools may have access to personal information needed to perform their functions, but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy. `}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
              Business Transfers.
            </TextComponent>
            {` In the event that goalskeeper.io creates, merges with, or is acquired by another entity, your information will most likely be transferred. goalskeeper.io will email you or place a prominent notice on our platform before your information becomes subject to another privacy policy.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
              Protection of goalskeeper.io and others.
            </TextComponent>
            {` We release personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms and conditions and other agreements, or protect the rights, property, or safety of goalskeeper.io, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>With Your Consent.</TextComponent>
            {` Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
              Anonymous Information.
            </TextComponent>
            {` goalskeeper.io uses the anonymous browsing information collected automatically by our servers primarily to help us administer and improve the platform. We may also use aggregated anonymous information to provide information about the platform to potential business partners and other unaffiliated entities. This information is not personally identifiable.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>Email Address.</TextComponent>
            {` The email address that you supply to us for purposes of receiving our email communications will never be rented or sold to a third party.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            7. PROTECTING YOUR INFORMATION
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you provide or share through the platform. If transactions are processed on the platform, transaction information is transmitted to and from the platform in encrypted form using industry-standard SSL connections to help protect such information from interception. We restrict authorized access to your personal information to those persons who have a legitimate need to know such information to provide certain functions and to those persons you have authorized to have access to such information.`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`goalskeeper.io follows generally accepted industry standards for data security to protect the personal information you provide and share through the platform, both during transmission and once goalskeeper.io receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while goalskeeper.io strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>8. RIGHTS</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.SUBHEADING}>
            {`Users who provide information through our platform, as data subjects and data owners have the right to access, rectify, download or delete their information, as well as to restrict and object to certain processing of their information. While some of these rights apply generally, others apply only in certain limited circumstances. We describe these rights below:`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
                Access and portability:
              </TextComponent>
              {` to access and know what information is stored in our servers, you can send us your request through our contact information.`}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
                Rectify, Restrict, Limit, and/or Delete:
              </TextComponent>
              {` You can also rectify, restrict, limit or delete much of your information.`}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
                Right to be informed:
              </TextComponent>
              {` Users of our platform will be informed, upon request, about what data we collect, how it is used, how long it is retained, and whether it is shared with third parties.`}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>Object:</TextComponent>
              {` When we process your information based on our legitimate interests as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have compelling legitimate reasons to continue processing it or where it is necessary for legal reasons.`}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>Revoke consent:</TextComponent>
              {` Where you have previously given your consent, such as to allow us to process and store your personal information, you have the right to revoke your consent to the processing and storage of your information at any time. For example, you may withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a legal basis for doing so or if your withdrawal of consent was limited to certain processing activities.`}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>Complaint:</TextComponent>
              {` If you wish to file a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users can exercise all these rights by contacting us through the contact information or the contact page.`}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
                Rights related to automated decision-making, including profiling:
              </TextComponent>
              {` platform users may request that we provide a copy of the automated processing activities we conduct if they believe that data is being unlawfully processed.`}
            </TextComponent>
          </TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`Users or owners of the personal information they provide through the platform may exercise these rights over their personal information at any time and without any limitation by sending us their request through our contact information.`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.PARAGRAPH_BOLD
            }>{`9. CHILDREN’S ONLINE PRIVACY PROTECTION`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`This privacy policy has been prepared and is maintained in accordance with all applicable national and international laws and regulations and, in particular, with the Israeli personal data protection laws (5741-1981) and the General Data Protection Regulation ("GDPR" - European regulation), regarding the protection of personal data of minors. Although the platform and products are available to all ages, we do not collect any information from children under the age of 13 without the respective permission of their parents or legal guardians. If you become aware that a child under the age of 13 has provided us with personal information without the permission of a parent or legal guardian, please contact us. If we become aware that a minor has provided us with personal information without parental or legal guardian permission, we will take steps to delete that information, terminate that person's account, and restrict access to that person's account.`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.PARAGRAPH_BOLD
            }>{`10. EDITING AND DELETING INFORMATION`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`If you believe that any information, we are holding on you is incorrect or incomplete, please write to or email us as soon as possible. We will promptly correct any information found to be incorrect. You can change, modify, correct and delete your information at any time, please contact us using the contact information. To stop receiving emails from goalskeeper.io, please follow the instructions included in the email. Your request should be processed within 48 hours.`}</TextComponent>
          <TextComponent
            variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>{`11. THIRD PARTIES`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and disclosure of information goalskeeper.io collects from you. If you disclose your information to others, whether other goalskeeper.io users or vendors, different rules may apply to their use or disclosure of the information you disclose to them. goalskeeper.io does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. goalskeeper.io is not responsible for the privacy or security practices of other platforms, including those that are linked to from goalskeeper.io.`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>{`12. CONTACT US`}</TextComponent>
          <TextComponent
            variant={
              TEXT_VARIANTS.SUBHEADING
            }>{`If you have any questions or concerns about this privacy policy and the processing and security of your data, please contact us via our contact page or by using the following contact information:`}</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.LINK} href="mailto:legal@goalskeeper.io">
            legal@goalskeeper.io
          </TextComponent>
        </div>
      </div>
    </HomePageComponent>
  );
}

export function Head() {
  return (
    <SeoComponent
      title="goalskeeper.io | privacy policy"
      description="Your business, your data, we care about your privacy. We care that you will achieve your business plan and reach your business goals."
      canonicalUrl="goalskeeper.io/"
      schema={MAIN_SCHEMA}
    />
  );
}
